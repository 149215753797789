import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.sass']
})
export class DatosPersonalesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DatosPersonalesComponent>) { }

  ngOnInit(): void {

  }

  close(){
    this.dialogRef.close();
  }

}
