import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-fondos-carousel',
  templateUrl: './fondos-carousel.component.html',
  styleUrls: ['./fondos-carousel.component.sass']
})
export class FondosCarouselComponent implements OnInit {
  @Input('fondo') fondos: Array<any> = [];
  public config: SwiperConfigInterface = {};
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 5,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }

}
