// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl:'http://localhost:4000',
  production: true,
  testing: false,
  firebase: {
    apiKey: "AIzaSyD6grXjF982zQb0h6anCVDq8vPnDUZJo2A",
    authDomain: "tienda-infondo.firebaseapp.com",
    projectId: "tienda-infondo",
    storageBucket: "tienda-infondo.appspot.com",
    messagingSenderId: "539058802834",
    appId: "1:539058802834:web:682d43f413ca6cf771d64b",
    measurementId: "G-0MDZS212KT"
  },
  keyEpayco: "97bbf6c0271bc1c4192a60557a03d4e0"
};

if(location.hostname == 'localhost'){
  environment.production = false;
  environment.testing = true;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
