import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd } from '@angular/router';
import { ProductService } from './components/shared/services/product.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'ecommerce mercado de las pulgas';
  public settings: Settings;
  public url : any;

  constructor(private spinner: NgxSpinnerService, public appSettings:AppSettings, public router: Router, private productService: ProductService) {
    this.settings = this.appSettings.settings;
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.url = event.url;
    //   }
    // } )
  }


  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0,0)
  });

  this.productService.getCategorias().subscribe((categorias: any) => {
    let categoriasQuery = [];
    categorias.forEach(categoria => {
      const data = categoria.data;
      data.id = categoria.id;
      categoriasQuery.push(data);
    })
    localStorage.setItem('categoriasMPU', JSON.stringify(categoriasQuery));
  })

  }

}
