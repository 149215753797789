import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';

// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireAuthModule } from '@angular/fire/auth';

import { MainComponent } from './components/main/main.component';

import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import { environment } from 'src/environments/environment';

import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { initializeApp } from 'firebase/app'; // Diego

//Diego

// TODO: Replace the following with your app's Firebase project configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDjoVeSP_Fc0nHb9Y9jikCMAMxLV94dzp8",
//   authDomain: "ecommerce-mpu.firebaseapp.com",
//   databaseURL: "https://ecommerce-mpu.firebaseio.com",
//   projectId: "ecommerce-mpu",
//   storageBucket: "ecommerce-mpu.appspot.com",
//   messagingSenderId: "280187230268",
//   appId: "1:280187230268:web:a8dc4beea72ce4c2b04b39",
//   measurementId: "G-222Z6JT65X"
// };

// const app = initializeApp(firebaseConfig); // Diego

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule,
    //Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    // Analytics
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
