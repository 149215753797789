import { Component, OnInit, ViewChild } from '@angular/core';
import { AdsService } from 'src/app/components/shared/services/ads.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.sass']
})
export class AdsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public ads = [];
  public searchAds = [];



  constructor(private adsSvc: AdsService) { }

  ngOnInit(): void {
    this.adsSvc.getAds().subscribe((ads: any[]) => {
      ads.forEach(ad => {
        ad.data.Correo = `mailto:${ad.data.Correo}`
        this.ads.push(ad);
      });
      this.ads = this.ads.sort((a, b) => 0.5 - Math.random());
      this.searchAds = [...this.ads];

      this.inicialPage();
      // this.linkListToPaginator();
    })
  }

  inicialPage(){
    this.ads = this.searchAds.slice(0, 5);
  }

  onPageEvent(event: PageEvent){
    this.ads = this.searchAds.slice(event.pageIndex*event.pageSize, event.pageIndex*event.pageSize + event.pageSize);
    // let startIndex = event.pageIndex * event.pageSize;
    // let endIndex = startIndex + event.pageSize;
    // if(endIndex > this.ads.length){
    //   endIndex = this.ads.length;
    // }
    // this.ads = [...this.searchAds].slice(startIndex, endIndex);
  }

  // linkListToPaginator(){
  //   merge(this.paginator.page).pipe(
  //     startWith({}),

  //     switchMap(() => {
  //       return of(this.searchAds);
  //     })
  //   )
  //   .subscribe(res => {
  //     const from = this.paginator.pageIndex;
  //     const to = from + 10;
  //     console.log(from);
  //     console.log(to);
  //     this.ads = res.slice(from, to);
  //   })
  // }



}
