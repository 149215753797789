import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Newsletters } from '../../../modals/newsletters';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { MessagesService } from '../services/messages.service';
import { EmailsService } from '../services/emails.service';
import { ClientsService } from '../services/clients.service';
import { MatDialog } from '@angular/material/dialog';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { InstruccionesComponent } from './instrucciones/instrucciones.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  constructor(private msj: MessagesService,
              private db: AngularFirestore,
              private emailSvc: EmailsService,
              private clientSvc: ClientsService,
              private dialog: MatDialog) { }

  //usuario = {} as Newsletters;
  newsForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  ngOnInit() {
  }

  addUsuario(){
    let exists = false;
    this.db.collection('usersNewsletter').get().subscribe((users) => {
      users.docs.forEach((user) => {
        const usuario = user.data();
        if(usuario.email == this.newsForm.value.email){
          exists = true;
        }
      });
      if (this.newsForm.status=='VALID'){
        if (!exists){
          this.db.collection('usersNewsletter').add(this.newsForm.value)
          .then(result => {
            this.clientSvc.createClient({Email: this.newsForm.value.email, Tipo: 'Boletin'})
            .then(result => {
              this.msj.mensajeCorrecto('Agregado', `El usuario ${this.newsForm.value.email} ha sido agregado correctamente al boletín`);

            })
          })
          .catch(err => {
          })
        }
        else {
          this.msj.mensajeError('Error', `El usuario ${this.newsForm.value.email} ya esta registrado en el boletín`);

        }
      }

    })

  }

  openDatosPersonlaesDialog(){
    let dialogRef = this.dialog.open(DatosPersonalesComponent, {
      panelClass: 'datos-personales'
    });
    dialogRef.afterClosed().subscribe()
  }

  openPoliticasDialog(){
    let dialogRef = this.dialog.open(PoliticasComponent, {
      panelClass: 'politicas'
    });
    dialogRef.afterClosed().subscribe()
  }

  openInstruccionesDialog(){
    let dialogRef = this.dialog.open(InstruccionesComponent, {
      panelClass: 'instrucciones'
    });
    dialogRef.afterClosed().subscribe()
  }

}
