import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  private adminAPI = (location.hostname != 'localhost') ? 'https://us-central1-admin-infondo.cloudfunctions.net/app' : "http://localhost:5001/admin-infondo/us-central1/app";

  constructor(private httpClient: HttpClient) { }

  public getAds() {
    return this.httpClient.get(`${this.adminAPI}/clasificados`);
  }
}
