import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sponsor-dialog',
  templateUrl: './sponsor-dialog.component.html',
  styleUrls: ['./sponsor-dialog.component.sass']
})
export class SponsorDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SponsorDialogComponent>, @Inject(MAT_DIALOG_DATA) public sponsor : any ) { }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

}
