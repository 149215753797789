import { Component, OnInit,ViewChild } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product, ColorFilter } from 'src/app/modals/product.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ExpositoresService } from 'src/app/components/shared/services/expositores.service';

@Component({
  selector: 'app-product-expositor-left-sidebar',
  templateUrl: './product-expositor-left-sidebar.component.html',
  styleUrls: ['./product-expositor-left-sidebar.component.sass']
})
export class ProductExpositorLeftSidebarComponent implements OnInit {
  @ViewChild(MatExpansionPanel, {static: true}) matExpansionPanelElement: MatExpansionPanel;

  public sidenavOpen:boolean = true;
  public animation:any;   // Animation
  public sortByOrder:string = '';   // sorting
  public page:any;
  public tagsFilters:any[] = [];
  public viewType:string = 'grid';
  public viewCol:number = 25;
  public colorFilters:ColorFilter[] = [];
  public items:Product[] = [];
  public allItems:Product[];
  public products:Product[] = [];
  public colors:any[] = [];
  public isLoaded: Promise<boolean>;
  public categorias = [];
  public categoriaSeleccionada:string = "Seleccione categoría";
  public panelOpenState: boolean = false;
  public expositores = [];
  public searchExpositores = [];

  constructor(private productService: ProductService,
              private route: ActivatedRoute,
              private expositorService: ExpositoresService,
              private router: Router) {
    this.expositores = [];
    this.searchExpositores = [];
  }

  ngOnInit(): void {

    this.route.params.subscribe(
      (params: Params) => {
        const category = params['category'].replace('/', '%2F');
        this.productService.getCategorias().subscribe((categorias: any) => {
          categorias.forEach(categoria => {
            const data = categoria.data;
            data.id = categoria.id;
            this.categorias.push(data);
          });
          if (category !== 'Emprendedor' && category !== 'Pyme' && category !== 'GrandesEmpresas'){
            this.expositores = [];
            this.searchExpositores = [];
            this.categoriaSeleccionada = "Seleccione categoría"
            let categoria: any = {};
            categoria = this.categorias.find(categoria => categoria.name == category);

            this.expositorService.getExpositorPorLineaProducto(categoria.id).subscribe((expositores: any[]) => {
              this.categoriaSeleccionada = category;

              expositores.forEach(expositor => {
                if(this.categorias){
                  let categoria: any = {};
                  categoria = this.categorias.find(categoria => categoria.id == expositor.data.LineaProducto1)
                  expositor.data.TallerLineaProducto = categoria.name;
                }
                this.expositores.push(expositor.data)
                this.searchExpositores.push(expositor.data)
              })
            })
          }
          else {
            this.expositores = [];
            this.searchExpositores = [];
            this.categoriaSeleccionada = "Seleccione categoría"
            this.expositorService.getExpositorPorCategoria(category).subscribe((expositores: any[]) => {
              expositores.forEach(expositor => {
                if(this.categorias){
                  let categoria: any = {};
                  categoria = this.categorias.find(categoria => categoria.id == expositor.data.LineaProducto1)
                  expositor.data.TallerLineaProducto = categoria.name;
                }
                this.expositores.push(expositor.data)
                this.searchExpositores.push(expositor.data)
              })
              this.expositores = this.expositores.sort((a, b) => 0.5 - Math.random());
            })
          }

        })



      }
    )
  }

  searchForm = new FormGroup({
    searchText: new FormControl('', [])
  })

  // Get current product tags
  /**public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }**/

  // Get current product colors
  public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  // Animation Effect fadeIn
  public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

  // Update tags filter
    /*public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }*/

    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

  // Initialize filetr Items
  /*public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });
}*/

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}

// Update price filter
//   public updatePriceFilters(price: any) {
//     let items: any[] = [];
//     this.products.filter((item: Product) => {
//         if (item.price >= price[0] && item.price <= price[1] )  {
//            items.push(item); // push in array
//         }
//     });
//     this.items = items;
// }
// Update price filter

public updatePriceFilters(price: any) {
   this.allItems = this.products.filter((item: Product) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });
}

searchProduct(){
  this.matExpansionPanelElement.toggle();

  this.products = this.allItems.filter((item: Product) => {
    try {
      return item.tags.indexOf(this.searchForm.value.searchText)>-1;
    } catch (error) {

    }
  });
}

onBrendsChanged(newBrend) {
  this.allItems = newBrend === 'all' ? this.products : this.products.filter(
    item => item.brand === newBrend
  )
}

selectCategory(category){
  this.route.params.subscribe((params: Params) => {
    const categoryParam = params['category'].replace('/', '%2F');
    if(categoryParam !== 'Emprendedor' && categoryParam !== 'Pyme' && categoryParam !== 'GrandesEmpresas'){
      this.router.navigate([`/home/expositor/${category}`])
    }
    else {
      this.categoriaSeleccionada = category;
      this.expositores.splice(0, this.expositores.length);
      let temp = this.searchExpositores.filter((expositor) => {
        return expositor.TallerLineaProducto.indexOf(category) !== -1 || !category;
      });
      this.expositores.push(...temp);
    }
  })

}
}
