import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import random from '@angular-devkit/schematics/src/rules/random';

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'USD';
  public catalogMode : boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;
  public bannerItems:any[];
  header = new HttpHeaders();

  public prodMap: Product[];

  private adminAPI = (location.hostname != 'localhost') ? 'https://us-central1-admin-infondo.cloudfunctions.net/app' : "http://localhost:5001/admin-infondo/us-central1/app"
  // private adminAPI = 'http://localhost:5001/orlondonol-2e688/us-central1/app'
  adminHeader = new HttpHeaders()

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar) {
    this.httpClient.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAwQMNzXX_aGhQSA7sxeGAcryAMjcLcaUU',{"returnSecureToken": false}).subscribe((anonymoususer:any) =>{
      this.header.append('Content-Type','application/json');
      this.header = this.header.append('Authorization','Bearer '+anonymoususer.idToken);
    });
   this.compareProducts.subscribe(products => products = products)
   this.adminHeader.append('Content-Type','application/json')
  }

  private products(): Observable<any[]> {
    return this.httpClient.get<any[]>('https://firestore.googleapis.com/v1/projects/orlondonol-2e688/databases/(default)/documents/Productos', {headers: this.header})
}

  public getCategories(): Observable<any[]>{
    return this.httpClient.get<any[]>('https://firestore.googleapis.com/v1/projects/orlondonol-2e688/databases/(default)/documents/Parametros',{headers:this.header})
  }

  public banners(): Observable<any[]>{
        return this.httpClient.get<any[]>('https://firestore.googleapis.com/v1/projects/orlondonol-2e688/databases/(default)/documents/Banner',{headers:this.header});
  }


    // Get Banners
    public getBanners() {
        return this.banners();
    }

    // Get Banners
    public getProducts(): Observable<Product[]> {
      return this.products();
    }


      // Get Products By Id
  public getProduct(id: string): Observable<Product> {
    return this.products().pipe(map(items => {
      return items.find((item: Product) =>
        { return item.id === id; });
      }));
    // return this.products.find(product=> product.id === id);

    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }
  public getProductById(id: string): Observable<any> {
    return this.httpClient.get<any[]>(`https://firestore.googleapis.com/v1/projects/orlondonol-2e688/databases/(default)/documents/Productos/${id}`, {headers: this.header})
  }


        /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

// Get Compare Products
public getComapreProducts(): Observable<Product[]> {
  const itemsStream = new Observable(observer => {
    observer.next(products);
    observer.complete();
  });
  return <Observable<Product[]>>itemsStream;
}

// If item is aleready added In compare
public hasProduct(product: Product): boolean {
  const item = products.find(item => item.id === product.id);
  return item !== undefined;
}

 // Add to compare
 public addToCompare(product: Product): Product | boolean {
  let message, status;
  var item: Product | boolean = false;
  if (this.hasProduct(product)) {
    item = products.filter(item => item.id === product.id)[0];
    const index = products.indexOf(item);
    this.snackBar.open('El producto  ' + product.name + ' ya lo adicionaste a la lista de comparación.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  } else {
    if(products.length < 4)
      products.push(product);
      message = 'Adicionaste el producto ' + product.name + ' a la lista de comparación.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

  }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
}

// Removed Product
public removeFromCompare(product: Product) {
  if (product === undefined) { return; }
  const index = products.indexOf(product);
  products.splice(index, 1);
  localStorage.setItem("compareItem", JSON.stringify(products));
}

   // Get Products By category
   public getProductByCategory(category: string): Observable<Product[]> {

     return this.products().pipe(map(items =>
       items.filter((item: Product) => {
         if(category == 'all')
            return item
            else
            return item.category === category;

       })
     ));

  }
  public getProductWithCategory(category: string): Observable<any[]>{
    const query =
    {
      "structuredQuery": {
        "from": [
          {
            "collectionId": "Productos"
          }
        ],
        "where": {
          "compositeFilter": {
            "op": "AND",
            "filters": [
              {
                "fieldFilter": {
                  "field": {
                    "fieldPath": "TallerLineaProducto"
                  },
                  "op": "EQUAL",
                  "value": {
                    "stringValue": category
                  }
                }
              },
              {
                "fieldFilter": {
                  "field": {
                    "fieldPath": "Valor"
                  },
                  "op": "GREATER_THAN",
                  "value": {
                    "integerValue": 0
                  }
                }
              }
            ]
          }
        }
      }
    }
    return this.httpClient.post<any[]>('https://firestore.googleapis.com/v1/projects/orlondonol-2e688/databases/(default)/documents:runQuery?', query, {headers: this.header})
  }

  /*
  public updateStock(id: string, stock: number){
    if(stock > 0){
      const patchStock =
      {
        "fields": {
          "Stock": {
            "integerValue": stock
          }
        }
      }
      this.httpClient.patch(`https://firestore.googleapis.com/v1beta1/projects/orlondonol-2e688/databases/(default)/documents/Productos/${id}?updateMask.fieldPaths=Stock`, patchStock, {headers: this.header})
      .subscribe((patch) => {
        return patch
      })
    }
    else {

    }


  }
  */

  // REST API FUNCTIONS
  public updateProducto(id: any, product: any){
    return this.httpClient.put(`${this.adminAPI}/productos/${id}`, product)
  }

  public getProducto(id: any){
    return this.httpClient.get(`${this.adminAPI}/productos/${id}`)
  }

  public getProductos(){
    return this.httpClient.get(`${this.adminAPI}/productos`)
  }

  public getProductoPorCategoria(categoria: any){
    return this.httpClient.get(`${this.adminAPI}/productos/categoria/${categoria}`)
  }

  public getProductosPorExpositor(expositor: any){
    return this.httpClient.get(`${this.adminAPI}/productos/expositor/${expositor}`)
  }

  public getProductosConLimite(limit){
    return this.httpClient.get(`${this.adminAPI}/productos/limit/${limit}`)
  }

  public getBanner(){
    return this.httpClient.get(`${this.adminAPI}/banner`)
  }

  public getCategorias(){
    return this.httpClient.get(`${this.adminAPI}/categorias`)
  }

  public getPatrocinadores(){
    return this.httpClient.get(`${this.adminAPI}/patrocinadores`)
  }

}
