import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ExpositoresService {

  private adminAPI = (location.hostname != 'localhost') ? 'https://us-central1-admin-infondo.cloudfunctions.net/app' : "http://localhost:5001/admin-infondo/us-central1/app";

  constructor(private httpClient: HttpClient,) { }

  public getExpositor(email: any) {
    return this.httpClient.get(`${this.adminAPI}/expositores/correo/${email}`);
  }

  public getExpositorPorCategoria(categoria: any){
    return this.httpClient.get(`${this.adminAPI}/expositores/${categoria}`);
  }

  public getExpositorPorLineaProducto(lineaProducto: any) {
    return this.httpClient.get(`${this.adminAPI}/expositores/lineaproducto/${lineaProducto}`);
  }
}
