import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-expositor',
  templateUrl: './expositor.component.html',
  styleUrls: ['./expositor.component.sass']
})
export class ExpositorComponent implements OnInit {

  @Input() expositor: any;

  constructor() { }

  ngOnInit(): void {
  }

}
