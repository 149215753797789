import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';

import { FormGroup, FormControl, Validators} from '@angular/forms';

import { AngularFirestore } from '@angular/fire/firestore';
import { MessagesService } from '../../../shared/services/messages.service';

import {AuthService} from '../../../shared/services/auth.service';
import { SnackbarService } from 'src/app/components/shared/services/snackbar.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass']
})
export class ProductDetailsComponent implements OnInit {

  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public product            :   any = {};
  public products           :   Product[] = [];

  public image: any;
  public zoomImage: any;

  public counter            :   number = 1;

  index: number;
  bigProductImageIndex = 0;

  public reviews: any[] = [];
  public currentUser: any;
  public isUser: boolean = false;

  reviewForm = new FormGroup({
    idProd: new FormControl(''),
    name: new FormControl('', [Validators.required],),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
    date: new FormControl('')
  })
  public categorias = JSON.parse(localStorage.getItem('categoriasMPU'));

  constructor(
    private route: ActivatedRoute,
    public productsService: ProductService,
    public dialog: MatDialog,
    private router: Router,
    private cartService: CartService,
    private db: AngularFirestore,
    private authSvc: AuthService,
    private msj: MessagesService,
    private snackBar: SnackbarService) { }

  ngOnInit() {
    //this.productsService.getProducts().subscribe(product => this.products = product);

    this.route.params.subscribe(params => {
      const id = params['id'];
      //this.productsService.getProduct(id).subscribe(product => this.product = product)

      this.productsService.getProducto(id).subscribe((product: any) => {
        let categoria = undefined;
          if(this.categorias){
            categoria = this.categorias.find(categoria => categoria.id == product.data.TallerLineaProducto)
          }
        ////
        //let idProd = product.name.split('/')
        let arrayPictures = [];
        arrayPictures.push(product.data.ImgUrl);
        product.data.Imagenes.forEach(image => {
          if(image){
            arrayPictures.push(image);
          }
        })

        this.product = {
          id: product.id,
          name: product.data.Nombre,
          price: product.data.Valor,
          salePrice: product.data.ValorConDescuento,
          discount: product.data.PorcentajeDescuento,
          // pictures: [{
          //   big: product.data.ImgUrl,
          //   small: product.data.Imagenes[0],
          //   small2: product.data.Imagenes[1],
          //   small3: product.data.Imagenes[2]
          // }],
          shortDetails: product.data.DescripcionCorta,
          pictures: arrayPictures,
          description: product.data.Descripcion,
          stock: product.data.Stock,
          brand: product.data.Taller,
          sale: (product.data.Saldo == 'Si') ? true : false,
          category: (categoria) ? categoria.name : '-' ,
          user: product.data.User,
          nationalShipmentCost: product.data.CostoEnvioNacional,
          localShipmentCost: product.data.CostoEnvioLocal,
          shipmentTime: product.data.TiempoEntrega,
          department: product.data.Departamento,
          city: product.data.Ciudad,
          epaycoID: product.data.EpaycoID,
          comision: product.data.Comision,
          iva: product.data.Iva
        }
        this.getCurrentUser();
        this.getReviews();


      })
    });


    //this.getRelatedProducts();
  }


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },


      }
    }
  }


  public openProductDialog(product, bigProductImageIndex) {
    let dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {product, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }


  public selectImage(index) {
    //
    //

    // this.product.pictures[index] = this.product.pictures[this.bigProductImageIndex];
    let aux = this.product.pictures[this.bigProductImageIndex];
    this.bigProductImageIndex = index;
    // this.product.pictures[index] = aux;
  }




public increment(product: any, quantity: number = 1) {
  this.counter += 1;
  if(this.counter > this.product.stock){
    this.counter -= 1;
    this.snackBar.showSnackBar(`El producto ${this.product.name} no tiene mas stock`)
  }
  // this.cartService.updateCartQuantity(product, quantity);
}

public decrement(product: any, quantity: number = -1) {
  // this.cartService.updateCartQuantity(product, quantity);
  if(this.counter >1){
     this.counter -= 1;
  }
}

getRelatedProducts() {
  this.productsService.getProducts()
  .subscribe(
    (product: Product[]) => {
      this.products = product
    });
}

  // Add to cart
  public addToCart(product: Product, quantity) {
    if (quantity == 0) return false;
    let producto = {...product};
    producto.pictures = [{big: product.pictures[0], small: product.pictures[0]}]
    this.cartService.addToCart(producto, parseInt(quantity));
  }

   // Add to cart
   public buyNow(product: Product, quantity) {
    if (quantity > 0)
      this.cartService.addToCart(product,parseInt(quantity));
      this.router.navigate(['/pages/checkout']);
 }



 public onMouseMove(e){
  if(window.innerWidth >= 1280){
    var image, offsetX, offsetY, x, y, zoomer;
    image = e.currentTarget;
    offsetX = e.offsetX;
    offsetY = e.offsetY;
    x = offsetX/image.offsetWidth*100;
    y = offsetY/image.offsetHeight*100;
    zoomer = this.zoomViewer.nativeElement.children[0];
    if(zoomer){
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
      zoomer.style.display = "block";
      zoomer.style.height = image.height + 'px';
      zoomer.style.width = image.width + 'px';
    }
  }
}

public onMouseLeave(event){
  this.zoomViewer.nativeElement.children[0].style.display = "none";
}

public openZoomViewer(){
  this.dialog.open(ProductZoomComponent, {
    data: this.zoomImage,
    panelClass: 'zoom-dialog'
  });
}

public addReview(){
  if(this.reviewForm.status == 'VALID'){
    this.reviewForm.value.idProd = this.product.id;
    this.reviewForm.value.name = this.currentUser.name;
    this.reviewForm.value.email = this.currentUser.email;
    this.reviewForm.value.date = Date.now();
    this.db.collection('reviews').add(this.reviewForm.value)
    //
    this.msj.mensajeCorrecto('Agregado', 'Comentario agregado correctamente');
    this.getReviews();
  }
}

public getReviews(){
  this.reviews = [];
  this.db.collection('reviews', ref => ref.where('idProd', '==', this.product.id)).get()
  .subscribe(reviews => {
    reviews.docs.forEach((review) => {
      this.reviews.push(review.data());
    })

  })
}

public getCurrentUser(){
  this.authSvc.afAuth.onAuthStateChanged(user => {
    if(user){

      this.db.collection('usuarios', ref => ref.where('email', '==', user.email)).get()
      .subscribe(usuario => {

        this.db.collection('ordenes', ref => ref.where('data_epayco_email_billing', '==', user.email).where('data_epayco_state', '==', 'Finalizado')).get()
        .subscribe(compro => {
          if(!compro.empty){
            this.isUser = true;
            this.currentUser = usuario.docs[0].data();
            //
            this.reviewForm.controls.name.disable();
            this.reviewForm.controls.email.disable();

            //
          }
        })

      })
    }
  })
}



}


