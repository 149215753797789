import { Component, OnInit, ɵConsole } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { CartService } from '../../shared/services/cart.service';
import {Router} from '@angular/router'
import { MatDialog } from '@angular/material/dialog';
import { SponsorDialogComponent } from './sponsor-dialog/sponsor-dialog.component';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.sass']
})
export class HomeTwoComponent implements OnInit {


  products: Product[];
  public banners = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Product[] = [];

  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;
  public categorias = JSON.parse(localStorage.getItem('categoriasMPU'));
  public fondos = [{
    ImgUrl: 'https://firebasestorage.googleapis.com/v0/b/admin-infondo.appspot.com/o/Logos%2FLOGO%20FECORA%20PNG.png?alt=media&token=7e3697ff-1b95-486f-b067-bcfcf2aa9456',
    Url: "https://www.fecora.com.co"

  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/tienda-infondo.appspot.com/o/Fondos%2Ffebanc.png?alt=media&token=eb6585a9-b509-4d99-b503-e47abed6a249",
    Url: "https://www.febanc.com.co"
  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/tienda-infondo.appspot.com/o/Fondos%2Ffemfuturo.png?alt=media&token=54a7cd94-f073-4d8c-8219-928ef63ea8b7",
    Url: "https://www.femfuturo.com.co"
  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/tienda-infondo.appspot.com/o/Fondos%2Fimage002.png?alt=media&token=e09a49a3-ab64-4294-8f9b-b23022e0c76a",
    Url: "https://www.fepep.com.co"
  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/tienda-infondo.appspot.com/o/Fondos%2Fimage006.png?alt=media&token=907668b5-cff3-40d5-a379-5344b70f402d",
    Url: "https://www.fondomanpower.com"
  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/admin-infondo.appspot.com/o/Logos%2Flogo-confamilia%20(2).png?alt=media&token=733ae8a5-b6a6-4b55-a8a8-f400fc907975",
    Url: "https://www.grupofamilia.com.co/es/Paginas/autenticacion-de-usuarios.aspx?Source=%2Ffondos%2Fes%2Fconfamilia"

  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/admin-infondo.appspot.com/o/Logos%2FLogo__Analfe_color.png?alt=media&token=f18ed1d3-d093-4235-a6ec-00e3a421b680",
    Url: "https://www.analfe.org.co"
  }, {
    ImgUrl: "https://firebasestorage.googleapis.com/v0/b/admin-infondo.appspot.com/o/Logos%2FFODELSA.jpeg?alt=media&token=8a1f4cfa-ba97-40ba-93d9-372aacfb456f",
    Url: "https://www.fodelsa.com.co"
  }]

  public patrocinadores = [];

  /*
  public slides = [
    { title: 'En descuento', subtitle: '75%', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Productos mas vendidos', subtitle: 'Selección especial', image: 'assets/images/carousel/banner4.jpg' },
  ];
  */
 public slides = [];

  constructor(private productService: ProductService,
              private cartService: CartService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit() {

    if (location.pathname == '/'){
      this.router.navigate(['home'])
    }
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.productService.getProductosConLimite('10').subscribe((products: any) => {
        this.products = [];

        products.forEach(producto => {
          let categoria = undefined;
          if(this.categorias){
            categoria = this.categorias.find(categoria => categoria.id == producto.data.TallerLineaProducto)
          }
          if(producto.data.Nombre != '' && producto.data.Valor >= 1000 && producto.data.Stock > 0) {
            this.products.push({
              id: producto.id,
              name: producto.data.Nombre,
              price: producto.data.Valor,
              salePrice: producto.data.ValorConDescuento,
              discount: producto.data.PorcentajeDescuento,
              pictures: [{
                big: producto.data.ImgUrl,
                small: producto.data.ImgUrl,
              }],
              shortDetails: producto.data.DescripcionCorta,
              description: producto.data.Descripcion,
              stock: producto.data.Stock,
              brand: producto.data.Taller,
              sale: (producto.data.Saldo == 'Si') ? true : false,
              category: (categoria) ? categoria.name : '-',
              user: producto.data.User,
              stars: (producto.data.Estrellas == undefined ) ? 0 : producto.data.Estrellas,
              nationalShipmentCost: producto.data.CostoEnvioNacional,
              localShipmentCost: producto.data.CostoEnvioLocal,
              shipmentTime: producto.data.TiempoEntrega,
              department: producto.data.Departamento,
              city: producto.data.Ciudad
            })
          }
        })

    })
    this.productService.getBanner().subscribe((banner: any) => {
      this.slides = [];
      banner.forEach(bann => {
        this.slides.push({
          image: bann.data.image,
          subtitle: bann.data.subtitle,
          title: bann.data.title,
          url: bann.data.url
        })
      })
    })

    this.productService.getPatrocinadores().subscribe((sponsors: any) => {
      this.patrocinadores = [];
      sponsors.forEach(sponsor => {
        this.patrocinadores.push(sponsor);
      })
    })
  }

  selectCategory(event){
    this.router.navigate([`/home/expositor/${event}`])
  }

  openSponsorDialog(patrocinador){
    let dialogRef = this.dialog.open(SponsorDialogComponent, {
      data: patrocinador,
      panelClass: 'sponsor-dialog'
    });
    dialogRef.afterClosed().subscribe(patrocinador => {

    })
  }






}
